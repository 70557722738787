import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Button } from 'antd';
import { LoginModal } from './Login';

function App() {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) return;

    iframeRef.current.contentWindow.postMessage({ token }, '*');
  }, [token]);

  return (
    <div className="wrapper">
      <header className="header">
        <div className="truncate">PARENT HEADER</div>
        <Button
          type="primary"
          onClick={() => {
            setIsModalVisible(true);
          }}
          size="large"
          style={{ width: '150px' }}
        >
          LOGIN
        </Button>
      </header>
      <iframe
        title="sport"
        ref={iframeRef}
        className="iframe"
        src={`${process.env.REACT_APP_IFRAME_URL}?token=${token}`}
      />
      <LoginModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        setToken={setToken}
      />
    </div>
  );
}

export default App;
